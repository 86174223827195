<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
        <template slot="title">
          <strong>Setting</strong>
          <a-button-group class="ml-2">
            <a-tooltip placement="top">
              <template slot="title">
                <span>Return To Page Before</span>
              </template>
              <a-button class="text-danger" @click="back"><a-icon type="arrow-left"></a-icon>return</a-button>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Saved Changes You've Been Doing!</span>
              </template>
              <a-button class="text-primary" @click="simpan">
                <a-icon type="check"></a-icon> Save Changes</a-button
              >
            </a-tooltip>
          </a-button-group>
        </template>
        <div class="row" style="padding: 25px">
          <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <center><h5>1</h5></center>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <center><h5>2</h5></center>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <center><h5>3</h5></center>
          </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
          <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 mt-1">
            <h5>Atibute</h5>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: Pembuat"
              v-model="input.paraf_1"
            ></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: Pemeriksa"
              v-model="input.paraf_2"
            ></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: Mengetahui"
              v-model="input.paraf_3"
            ></a-input>
          </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
          <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 mt-1">
            <h5>Nama</h5>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: Alfi Sakdiyah"
              v-model="input.nama_1"
            ></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: NURLAILA"
              v-model="input.nama_2"
            ></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: MUHAMMAD YAZID"
              v-model="input.nama_3"
            ></a-input>
          </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
          <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 mt-1">
            <h5>Jabatan</h5>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: Teller"
              v-model="input.jabatan_1"
            ></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: Wakil Manajer"
              v-model="input.jabatan_2"
            ></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: Manajer"
              v-model="input.jabatan_3"
            ></a-input>
          </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
export default {
  created() {
    this.getSettings()
  },
  data() {
    return {
      input: {
        paraf_1: '',
        nama_1: '',
        jabatan_1: '',
        paraf_2: '',
        nama_2: '',
        jabatan_2: '',
        paraf_3: '',
        nama_3: '',
        jabatan_3: '',
      },
      response: null,
    }
  },
  methods: {
    moment,
    async getSettings() {
      var res = await lou.customUrlGet2('transaksi/simpanan/pengesahan')
      this.input = res.data
      // this.input.periode = moment(res.data.periode, 'YYYY-MM-DD')
      // console.log('this.input', this.input)
      // this.response = this.$g.clone(res.data)
    },
    async simpan() {
      var fd = this.input
      // fd.periode = this.input.periode.format('YYYY-MM-DD')
      // var res =
      var res = await lou.customUrlPost2('transaksi/simpanan/pengesahan', fd)
      if (res) {
        this.getSettings()
        // this.$store.commit('user/SET_STATE', {
        // periode: this.input.periode.format('YYYY-MM-DD'),
        // })
        // localStorage.setItem(
        //   'userData',
        //   JSON.stringify(this.$store.state.user),
        // )
      }
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped></style>
